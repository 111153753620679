import CleanlabLogoLight from '@assets/images/branding/cleanlab_logo-black.png'
import CleanlabLogoDark from '@assets/images/branding/cleanlab_logo-white.png'
import { cn } from '@utils/tailwindUtils'

const baseClass = 'home-tour-logo-image block'

const LogoImage = ({
  width = 32,
  height = 32,
  className,
}: {
  width?: number
  height?: number
  className?: string
}) => {
  return (
    <>
      <img
        src={CleanlabLogoLight}
        alt="CleanLab"
        className={cn(baseClass, 'dark:hidden', className)}
        width={`${width}`}
        height={`${height}`}
      />
      <img
        src={CleanlabLogoDark}
        alt="CleanLab"
        className={cn(baseClass, 'hidden dark:block', className)}
        width={`${width}`}
        height={`${height}`}
      />
    </>
  )
}

export { LogoImage }
