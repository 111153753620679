import { Box, Center, Spinner, useDisclosure } from '@chakra-ui/react'
import { Button } from '@components/button/Button'
import { IconPlus } from '@components/icons'
import { Input, InputSearch } from '@components/input/Input'
import { Popover, PopoverContent, PopoverTrigger } from '@components/popover/Popover'
import { useKeyPress } from '@hooks/useKeyPress'
import useOutsideClick from '@hooks/useOutsideClick'
import { useWindowSize } from '@hooks/useWindowSize'
import { useSubscription } from '@providers/billing/SubscriptionProvider'
import { NotificationSetting } from '@services/userNotificationSettings/constants'
import { useDisableNotificationSetting } from '@services/userNotificationSettings/mutations'
import { useNotificationSetting } from '@services/userNotificationSettings/queries'
import { cn } from '@utils/tailwindUtils'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Tasktype } from 'src/pages/datasetUpload/DatasetUpload.types'

import { isSuggestionHidden } from '../editInterface/EditInterface.helpers'
import {
  calculatePaletteListHeight,
  EXCLUDE_COMMAND_NAME,
  getAddLabelCommands,
  getFilteredCommands,
  getPaletteCommands,
  getRelabelCommands,
} from './CommandPalette.helpers'
import {
  ActionType,
  CommandPaletteProps,
  CommandProps,
  CustomTagType,
} from './CommandPalette.types'
import MulticlassPaletteCommands from './multiclassPaletteCommands/MulticlassPaletteCommands'
import MultilabelPaletteCommands from './multilabelPaletteCommands/MultilabelPaletteCommands'
import NewLabelAddedModal from './newLabelAddedModal/NewLabelAddedModal'

const CommandPalette = (props: CommandPaletteProps) => {
  const {
    selectedRowIndex,
    labels,
    givenLabel,
    suggestedLabel,
    suggestedAction,
    labelToProba,
    updateRowAction,
    updateRowCustomTag,
    isLabelIssue,
    isUnlabeled,
    suggestExclude,
    taskType,
    correctedLabel,
    selectedRows,
    handleMultiSelectAction,
    isLoading,
    isActionLoading,
    resolverWidth,
    isFetchingRows,
    showDataLabelingWorkflow,
    givenLabelType,
    currentRowAction,
    autoAdvance,
    newlyAddedLabels,
  } = props

  const [inputValue, setInputValue] = useState('')
  const [newLabelInput, setNewLabelInput] = useState('')
  const [addPopoverOpen, setAddPopoverOpen] = useState(false)
  const newlyAddedLabelsMemoized = JSON.stringify(newlyAddedLabels)

  const [hideNewLabelAddedModal, setHideNewLabelAddedModal] = useState<boolean>(false)
  const windowSize = useWindowSize()
  const { showNotificationBanner } = useSubscription()

  const paletteRef = useRef<HTMLDivElement>(null)
  const popoverContentRef = useRef<HTMLDivElement>(null)

  const {
    isOpen: isNewLabelAddedModalOpen,
    onOpen: onNewLabelAddedModalOpen,
    onClose: onNewLabelAddedModalClose,
  } = useDisclosure()

  const showNewAddedLabelModal = useNotificationSetting(NotificationSetting.ShowNewAddedLabelModal)
  const { mutate: disableNotificationSetting } = useDisableNotificationSetting(
    NotificationSetting.ShowNewAddedLabelModal
  )
  const handleNewLabelAddedModalClose = () => {
    if (hideNewLabelAddedModal) {
      disableNotificationSetting()
    }
    onNewLabelAddedModalClose()
  }

  const markUnresolvedAction = useCallback(() => {
    updateRowAction(ActionType.MARK_UNRESOLVED)
  }, [updateRowAction])

  // clear search input when selected row changes
  useEffect(() => {
    setInputValue('')
  }, [setInputValue, selectedRowIndex])

  const isMultiSelect = selectedRows.length > 1

  const givenLabelArray = givenLabel.split(',')
  const suggestedLabelArray = suggestedLabel.split(',')

  // append sort probability for each label in multilabel
  const labelsWithSortProbs = labels.map((label) => ({
    label: label,
    probability: suggestedLabelArray.includes(label)
      ? labelToProba[label] ?? 0
      : 1 - (labelToProba[label] ?? 0),
  }))

  // Labels where given !== suggested are displayed, ordered by suggested probability descending, 1st.
  // Then labels where given === suggested are displayed, ordered by suggested probability ascending, 2nd.
  const multilabelsOrdered = [
    ...labelsWithSortProbs
      .filter(
        (e) =>
          (suggestedLabelArray.includes(e.label) && !givenLabelArray.includes(e.label)) ||
          (!suggestedLabelArray.includes(e.label) && givenLabelArray.includes(e.label))
      )
      .sort((a, b) => b.probability - a.probability),
    ...labelsWithSortProbs
      .filter(
        (e) =>
          (suggestedLabelArray.includes(e.label) && givenLabelArray.includes(e.label)) ||
          (!suggestedLabelArray.includes(e.label) && !givenLabelArray.includes(e.label))
      )
      .sort((a, b) => a.probability - b.probability),
  ].map((labelWithProb) => labelWithProb.label)

  const handleActionSelect = useCallback(
    (action: string, label?: string | number | boolean, isNewLabel = false) => {
      if (isMultiSelect) {
        handleMultiSelectAction(action, label ?? '')
      } else {
        updateRowAction(action)
      }
      setInputValue('')
      setNewLabelInput('')
      if (isNewLabel) {
        onNewLabelAddedModalOpen()
      }
    },
    [handleMultiSelectAction, isMultiSelect, onNewLabelAddedModalOpen, updateRowAction]
  )
  const fastMode = inputValue.length === 0

  const hideSuggestion = isSuggestionHidden(
    isLabelIssue,
    isUnlabeled,
    suggestExclude,
    givenLabel,
    suggestedLabel
  )

  const defaultCommands = useMemo(() => {
    return getPaletteCommands({
      isUnlabeled: isUnlabeled,
      isMultiSelect: isMultiSelect,
      suggestedLabel:
        suggestedAction === ActionType.EXCLUDE ? EXCLUDE_COMMAND_NAME : suggestedLabel,
      givenLabel: givenLabel,
      suggestExclude: suggestExclude,
      suggestedAction: suggestedAction,
      labelToProbability: labelToProba,
      hideSuggestion: hideSuggestion,
      handleActionSelect: handleActionSelect,
    })
  }, [
    givenLabel,
    handleActionSelect,
    hideSuggestion,
    isMultiSelect,
    isUnlabeled,
    labelToProba,
    suggestExclude,
    suggestedAction,
    suggestedLabel,
  ])

  const relabelCommands: CommandProps[] = getRelabelCommands({
    labels: labels,
    isMultiSelect: isMultiSelect,
    givenLabel: givenLabel,
    hideSuggestion: hideSuggestion,
    suggestedLabel: suggestedLabel,
    labelToProba: labelToProba,
    setInputValue: setInputValue,
    handleActionSelect: handleActionSelect,
  })

  const addedLabelCommand: CommandProps[] = getAddLabelCommands(
    inputValue,
    isMultiSelect,
    handleActionSelect,
    setInputValue
  )

  const allFastCommands: CommandProps[] = defaultCommands.concat(relabelCommands)

  // Note: we only want the order to shift dynamically if isMultiSelect, selectedRowIndex, autoAdvance, or newlyAddedLabels values change.
  // We do not want to recalculate when allFastCommands, correctedLabel, or currentRowAction change
  const allFastCommandsOrdered = useMemo(() => {
    if (!isMultiSelect && selectedRowIndex !== -1) {
      const targetCommandName = currentRowAction === 'exclude' ? 'Exclude' : correctedLabel
      const index = allFastCommands.findIndex((command) => command.name === targetCommandName)
      if (index !== -1) {
        const updatedCommands = [
          allFastCommands[index],
          ...allFastCommands.slice(0, index),
          ...allFastCommands.slice(index + 1),
        ]
        return updatedCommands
      }
    }
    return allFastCommands
  }, [isMultiSelect, autoAdvance, newlyAddedLabelsMemoized, selectedRows]) // eslint-disable-line react-hooks/exhaustive-deps

  const allSearchCommands: CommandProps[] = defaultCommands.concat(relabelCommands).map((e) => {
    return { ...e, shortcut: null }
  })

  const filteredCommands = useMemo(() => {
    return getFilteredCommands(fastMode, allFastCommandsOrdered, inputValue, allSearchCommands)
  }, [allFastCommandsOrdered, allSearchCommands, fastMode, inputValue])

  const displayedCommands = filteredCommands.length > 0 ? filteredCommands : addedLabelCommand

  useOutsideClick(popoverContentRef, () => {
    if (addPopoverOpen) {
      setAddPopoverOpen(false)
    }
  })

  useKeyPress({
    callback: () =>
      isMultiSelect
        ? handleMultiSelectAction(ActionType.EXCLUDE)
        : updateRowAction(ActionType.EXCLUDE),
    keys: ['e'],
  })

  useKeyPress({
    callback: () => {
      if (!showDataLabelingWorkflow) {
        if (isMultiSelect) {
          handleMultiSelectAction(CustomTagType.MARK_NEEDS_REVIEW)
        } else {
          updateRowCustomTag(CustomTagType.NEEDS_REVIEW)
        }
      }
    },
    keys: ['n'],
  })

  useKeyPress({
    callback: () =>
      isMultiSelect
        ? handleMultiSelectAction(ActionType.AUTO_FIX)
        : updateRowAction(suggestedAction),
    keys: ['w'],
    enabled: taskType === Tasktype.MULTILABEL,
  })

  useKeyPress({
    callback: () => updateRowAction(ActionType.KEEP),
    keys: ['q'],
    enabled: taskType === Tasktype.MULTILABEL,
  })

  useKeyPress({
    callback: () =>
      isMultiSelect
        ? handleMultiSelectAction(ActionType.MARK_UNRESOLVED)
        : updateRowAction(ActionType.MARK_UNRESOLVED),
    keys: ['c'],
  })

  onkeydown = () => null

  const CommandListComponent = useMemo(() => {
    if (isLoading || (isActionLoading && taskType === Tasktype.MULTICLASS)) {
      return (
        <Center h="100%">
          <Spinner size="xl" />
        </Center>
      )
    }
    if (taskType === Tasktype.MULTICLASS) {
      return (
        <MulticlassPaletteCommands
          displayedCommands={displayedCommands}
          inputValue={inputValue}
          labelToProba={labelToProba}
          selectedRowIndex={selectedRowIndex}
          selectedRows={selectedRows}
          givenLabelType={givenLabelType}
          correctedLabel={correctedLabel ?? ''}
          markUnresolvedAction={markUnresolvedAction}
          currentRowAction={currentRowAction ?? ''}
          newlyAddedLabels={newlyAddedLabels}
        />
      )
    } else if (taskType === Tasktype.MULTILABEL) {
      return (
        <MultilabelPaletteCommands
          labelToProba={labelToProba}
          suggestedLabel={suggestedLabel}
          givenLabel={givenLabel}
          updateRowAction={updateRowAction}
          correctedLabel={correctedLabel ?? ''}
          isActionLoading={isActionLoading}
          resolverWidth={resolverWidth}
          windowSize={windowSize}
          isFetchingRows={isFetchingRows}
          selectedRowIndex={selectedRowIndex}
          multilabelsOrdered={multilabelsOrdered}
          inputValue={inputValue}
          showNotificationBanner={showNotificationBanner}
        />
      )
    }
  }, [
    correctedLabel,
    currentRowAction,
    displayedCommands,
    givenLabel,
    givenLabelType,
    inputValue,
    isActionLoading,
    isFetchingRows,
    isLoading,
    labelToProba,
    markUnresolvedAction,
    multilabelsOrdered,
    resolverWidth,
    selectedRowIndex,
    selectedRows,
    showNotificationBanner,
    suggestedLabel,
    taskType,
    updateRowAction,
    windowSize,
    newlyAddedLabels,
  ])

  const commandPaletteClass = cn('h-full flex-col items-start bg-surface-1')

  return (
    <div className={commandPaletteClass}>
      <div className="w-full gap-5 px-5 pt-5">
        <div className="h-full w-full">
          {isMultiSelect && taskType === Tasktype.MULTILABEL ? (
            <Center h="60%" px={6} fontSize="sm">
              Multi-select label actions are not currently supported for multi-label Projects
            </Center>
          ) : (
            <Box ref={paletteRef} h="100%">
              <div className="flex flex-row gap-4 pb-6">
                <InputSearch
                  className="[&>input]:min-w-0" // override input's min-width
                  value={inputValue}
                  placeholder="Type to search"
                  onChange={(evt) => {
                    evt.stopPropagation()
                    setInputValue(evt.target.value)
                  }}
                  onKeyDown={(evt) => evt.stopPropagation()}
                />
                {taskType === Tasktype.MULTICLASS && (
                  <Popover open={addPopoverOpen}>
                    <PopoverTrigger
                      className="focus-visible:outline-none focus-visible:ring-0"
                      tabIndex={-1}
                    >
                      <Button
                        variant="secondary"
                        size="medium"
                        iconEnd={<IconPlus />}
                        onClick={() => setAddPopoverOpen(!addPopoverOpen)}
                        onMouseDown={(evt) => evt.stopPropagation()} // prevent useOutsideClick from closing
                      >
                        Add
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent
                      ref={popoverContentRef}
                      className="flex flex-col gap-5 rounded-2 border border-border-1 bg-surface-0 px-5 pb-5 pt-4"
                    >
                      <Input
                        onChange={(evt) => {
                          setNewLabelInput(evt.target.value)
                        }}
                        onKeyDown={(evt) => evt.stopPropagation()}
                        placeholder="Enter new label here"
                      />
                      <Button
                        className="w-full justify-center"
                        onClick={() => {
                          handleActionSelect(
                            isMultiSelect ? 'relabel' : `relabel-${newLabelInput}`,
                            newLabelInput,
                            true
                          )
                          setAddPopoverOpen(false)
                        }}
                      >
                        Apply
                      </Button>
                    </PopoverContent>
                  </Popover>
                )}
              </div>
              <div
                style={{
                  height: calculatePaletteListHeight(
                    windowSize.height,
                    isMultiSelect,
                    showDataLabelingWorkflow,
                    showNotificationBanner
                  ),
                }}
              >
                {CommandListComponent}
              </div>
            </Box>
          )}
        </div>
      </div>
      <NewLabelAddedModal
        isOpen={isNewLabelAddedModalOpen && showNewAddedLabelModal}
        onClose={handleNewLabelAddedModalClose}
        setHideNewLabelAddedModal={setHideNewLabelAddedModal}
      />
    </div>
  )
}

export default CommandPalette
