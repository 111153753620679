import { auth, createAuthHeaders } from '@providers/authentication/AuthProviderWithHistory'
import { GetUserQuotaUsageRes, queryKeys, UserQuota } from '@services/userQuota/constants'
import { REACT_APP_CLEANLAB_API_URL } from '@utils/environmentVariables'
import axios from 'axios'
import { useQuery } from 'react-query'

const apiVersion = 'v1'

const axiosClient = axios.create({
  baseURL: `${REACT_APP_CLEANLAB_API_URL}/api/${apiVersion}/user-quotas`,
  withCredentials: true,
})

export const useUserQuotas = (): UserQuota[] => {
  const { data } = useQuery({
    queryKey: queryKeys.quotas.all(),
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const res = await axiosClient.get<{ rows: GetUserQuotaUsageRes[] }>(
        '/',
        createAuthHeaders(accessToken)
      )
      return res.data?.rows?.map((r) => ({
        userId: r.user_id,
        name: r.name,
        email: r.email,
        dataStorage: r.data_storage,
        dataStorageLimit: r.data_storage_limit,
        cleansetLimit: r.cleanset_limit,
        tlmTokenLimit: r.tlm_token_limit,
        tlmTokenPerMinuteLimit: r.tlm_token_per_minute_limit,
        createdAt: r.created_at,
        lastUpdated: r.last_updated,
        numFreeProjectsUsed: r.num_free_projects_used,
        numFreeModelsUsed: r.num_free_models_used,
        numFreeCleansetExportRowsUsed: r.num_free_cleanset_export_rows_used,
        numFreeInferenceExportRowsUsed: r.num_free_inference_rows_used,
        numTlmTokensUsed: r.num_tlm_tokens_used,
      }))
    },
    meta: { error: 'Failed to fetch user quota data.' },
  })
  return data ?? []
}

export const useUserQuota = ({ enabled }: { enabled: boolean }): UserQuota | undefined => {
  const { data } = useQuery({
    queryKey: queryKeys.quotas.self(),
    queryFn: async () => {
      const accessToken = await auth.getTokenSilently()
      const { data } = await axiosClient.get<GetUserQuotaUsageRes>(
        '/usage',
        createAuthHeaders(accessToken)
      )
      return {
        userId: data.user_id,
        dataStorage: data.data_storage,
        dataStorageLimit: data.data_storage_limit,
        cleansetLimit: data.cleanset_limit,
        tlmTokenLimit: data.tlm_token_limit,
        createdAt: data.created_at,
        lastUpdated: data.last_updated,
        numFreeModelsUsed: data.num_free_models_used,
        numFreeProjectsUsed: data.num_free_projects_used,
        numFreeCleansetExportRowsUsed: data.num_free_cleanset_export_rows_used,
        numFreeInferenceExportRowsUsed: data.num_free_inference_rows_used,
      }
    },
    meta: { error: 'Failed to fetch user quota data.' },
    enabled: !!enabled,
  })
  return data
}
