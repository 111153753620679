import React from 'react'

import { SvgIconProps } from '../icons/IconProps.types'

const CleanlabIcon = ({ width = '39', height = '42', color = '#090E15' }: SvgIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 39 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_143_137)">
        <path
          d="M31.58 3.17V6.85C28.59 4.56 24.86 3.21 20.82 3.21C11.01 3.21 3.07 11.17 3.07 21C3.07 30.82 11.01 38.78 20.82 38.78C24.86 38.78 28.59 37.43 31.58 35.14V38.82C28.44 40.73 24.76 41.83 20.82 41.83C9.34 41.83 0.04 32.50 0.04 21C0.04 9.49 9.34 0.16 20.82 0.16C24.76 0.16 28.44 1.26 31.58 3.17Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.80 4.16C11.58 4.16 4.11 11.68 4.11 20.95C4.11 30.22 11.58 37.74 20.80 37.74C30.03 37.74 37.50 30.22 37.50 20.95C37.50 11.68 30.03 4.16 20.80 4.16ZM3.50 20.95C3.50 11.34 11.25 3.56 20.80 3.56C30.36 3.56 38.11 11.34 38.11 20.95C38.11 30.56 30.36 38.35 20.80 38.35C11.25 38.35 3.50 30.56 3.50 20.95Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.24 22.30C9.28 22.30 7.70 23.85 7.70 25.74C7.70 27.62 9.28 29.17 11.24 29.17C13.21 29.17 14.79 27.62 14.79 25.74C14.79 23.85 13.21 22.30 11.24 22.30ZM7.18 25.74C7.18 23.54 9.01 21.78 11.24 21.78C13.48 21.78 15.31 23.54 15.31 25.74C15.31 27.93 13.48 29.69 11.24 29.69C9.01 29.69 7.18 27.93 7.18 25.74Z"
          fill={color}
        />
        <path
          d="M19.25 15.52C21.33 15.52 23.01 13.86 23.01 11.82C23.01 9.78 21.33 8.12 19.25 8.12C17.17 8.12 15.48 9.78 15.48 11.82C15.48 13.86 17.17 15.52 19.25 15.52Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.25 8.38C17.31 8.38 15.74 9.93 15.74 11.82C15.74 13.71 17.31 15.25 19.25 15.25C21.19 15.25 22.75 13.71 22.75 11.82C22.75 9.93 21.19 8.38 19.25 8.38ZM15.22 11.82C15.22 9.63 17.03 7.86 19.25 7.86C21.46 7.86 23.27 9.63 23.27 11.82C23.27 14.01 21.46 15.78 19.25 15.78C17.03 15.78 15.22 14.01 15.22 11.82Z"
          fill={color}
        />
        <path
          d="M27.64 29.43C29.72 29.43 31.40 27.78 31.40 25.74C31.40 23.69 29.72 22.04 27.64 22.04C25.56 22.04 23.88 23.69 23.88 25.74C23.88 27.78 25.56 29.43 27.64 29.43Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.64 22.30C25.70 22.30 24.14 23.84 24.14 25.74C24.14 27.63 25.70 29.17 27.64 29.17C29.58 29.17 31.14 27.63 31.14 25.74C31.14 23.84 29.58 22.30 27.64 22.30ZM23.62 25.74C23.62 23.54 25.42 21.78 27.64 21.78C29.86 21.78 31.66 23.54 31.66 25.74C31.66 27.93 29.86 29.69 27.64 29.69C25.42 29.69 23.62 27.93 23.62 25.74Z"
          fill={color}
        />
        <path d="M20.93 10.12H17.30V27.08H20.93V10.12Z" fill={color} />
        <path d="M28.03 23.87H17.30V27.08H28.03V23.87Z" fill={color} />
      </g>
      <defs>
        <clipPath id="clip0_143_137">
          <rect width="38.15" height="41.75" fill={color} transform="translate(0 0.12)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CleanlabIcon
