import './Landing.css'

import LandingGraphicFallback from '@assets/images/branding/landing_graphic.png'
import LandingGraphic from '@assets/images/landing/landing_graphic.png'
import {
  Box,
  Center,
  Circle,
  HStack,
  Image,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react'
import Layout from '@common/layout/Layout'
import CleanlabIcon from '@components/branding/CleanlabIcon'
import CleanlabText from '@components/branding/CleanlabText'
import PrimaryButton from '@components/buttons/primaryButton/PrimaryButton'
import SecondaryButton from '@components/buttons/secondaryButton/SecondaryButton'
import Link from '@components/link/Link'
import { useAuth } from '@hooks/useAuth'
import { AUTH_MODE_OAUTH } from '@utils/environmentVariables'
import React, { useEffect } from 'react'
import { FiExternalLink } from 'react-icons/fi'
import { useSearchParams } from 'react-router-dom'

import { cleanlabColors } from '../../assets/styles/CleanlabColors'

// used for SaaS: we show both "Sign up" and "Log in" buttons
const SignUpOrLogIn = () => {
  const { loginWithRedirect } = useAuth()

  return (
    <Stack w="100%" direction={{ base: 'column', sm: 'row' }} spacing="4">
      <PrimaryButton
        height="40px"
        fontSize="16px"
        ariaLabel="sign up"
        onClick={() =>
          loginWithRedirect({
            authorizationParams: {
              screen_hint: 'signup',
            },
          })
        }
      >
        Sign up
      </PrimaryButton>
      <SecondaryButton
        height="40px"
        fontSize="16px"
        onClick={() => loginWithRedirect({})}
        ariaLabel="log in"
      >
        Log in
      </SecondaryButton>
    </Stack>
  )
}

// used for VPC: we only show the log in button, because users can't sign up
// for a new account here, they already need an account with their identity
// provider
const LogIn = () => {
  const { loginWithRedirect } = useAuth()

  return (
    <Stack w="100%" direction={{ base: 'column', sm: 'row' }} spacing="4">
      <PrimaryButton
        height="40px"
        fontSize="16px"
        ariaLabel="log in"
        onClick={() => loginWithRedirect({})}
      >
        Log in
      </PrimaryButton>
    </Stack>
  )
}

const Landing = () => {
  const textColor = useColorModeValue('neutral.700', 'neutralDarkMode.700')
  const boldTextColor = useColorModeValue('neutral.900', 'neutralDarkMode.900')
  const linkColor = useColorModeValue('neutral.800', 'neutralDarkMode.800')
  const iconColor = useColorModeValue(
    cleanlabColors.neutral[900],
    cleanlabColors.neutralDarkMode[900]
  )

  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (searchParams.get('signup_origin') === 'TLM') {
      localStorage.setItem('signup_origin', 'TLM')
      searchParams.delete('signup_origin')
      setSearchParams(searchParams)
    }
  }, [searchParams, setSearchParams])

  return (
    <Layout requiresLogin={false}>
      <HStack h="100vh" w="100vw" justify="space-between">
        <Box h="100%" w={useBreakpointValue({ sm: '100%', md: '100%', lg: '50%', xl: '50%' })}>
          <VStack
            h="100%"
            spacing="24px"
            alignItems="flex-start"
            mx={{ base: '16px', sm: '120px', md: '120px' }}
            pt={useBreakpointValue({
              base: '40px',
              sm: '130px',
              lg: '0',
            })}
            justifyContent={useBreakpointValue({
              sm: 'flex-start',
              lg: 'center',
            })}
          >
            <Stack spacing={{ base: '8', md: '12' }}>
              <Stack spacing={{ base: '4', md: '6' }} maxW={{ md: 'xl', lg: 'md', xl: 'xl' }}>
                <Link
                  href="https://cleanlab.ai"
                  isExternal
                  className="inline-flex items-center gap-4 self-start"
                >
                  <CleanlabIcon color={iconColor} width="38px" height="42px" />
                  <CleanlabText color={iconColor} width="100px" />
                </Link>
                <Text fontSize="32px" fontWeight="600" color={boldTextColor}>
                  Data you can trust
                </Text>
                <Text fontSize="18px" fontWeight="400" color={textColor}>
                  Cleanlab adds trust to every input and every output of data driven solutions like
                  LLMs, ML models, analytics, product catalogs, data warehouses, and BI systems.
                </Text>
              </Stack>
            </Stack>
            {AUTH_MODE_OAUTH ? <LogIn /> : <SignUpOrLogIn />}
            <HStack
              cursor="pointer"
              gap="6px"
              onClick={() =>
                window.open('https://help.cleanlab.ai/guide/quickstart/web/', '_blank')
              }
            >
              <Text fontSize="16px" fontWeight="500" color={linkColor}>
                Explore docs and tutorials
              </Text>
              <FiExternalLink size={20} />
            </HStack>
          </VStack>
        </Box>
        <Center
          display={useBreakpointValue({
            base: 'none',
            sm: 'none',
            md: 'none',
            lg: 'flex',
          })}
          className="landing-graphic__container"
          bg="neutralDarkMode.50"
        >
          <Circle className="circle-1" />
          <Circle className="circle-2" />
          <Image
            className="landing-graphic"
            src={LandingGraphic}
            alt=""
            fallback={<Image className="landing-graphic" src={LandingGraphicFallback} alt="" />}
          />
        </Center>
      </HStack>
    </Layout>
  )
}

export default Landing
