import { AppState, Auth0Provider } from '@auth0/auth0-react'
import { Auth0Client } from '@auth0/auth0-spa-js'
import {
  AUTH_MODE_OAUTH,
  ENV_SETTINGS,
  EnvironmentType,
  STUDIO_COOKIE_DOMAIN,
} from '@utils/environmentVariables'
import React, { PropsWithChildren } from 'react'
import { useNavigate } from 'react-router-dom'

const CLIENT_IDS: Record<EnvironmentType, string> = {
  production: '2ypiGF66Pe2gGwIOqNrChdaahYCMIxHG',
  staging: '1dBmbQw0YgHxNcYOSWm4G3pqDG75vJgr',
  local_staging: '1dBmbQw0YgHxNcYOSWm4G3pqDG75vJgr',
  development: 'U2bFiyyhlMbOaIIM12zLPdUDUzrmRyn1',
  infra_dev: 'U2bFiyyhlMbOaIIM12zLPdUDUzrmRyn1',
  test: 'U2bFiyyhlMbOaIIM12zLPdUDUzrmRyn1',
  hosted_dev: 'U2bFiyyhlMbOaIIM12zLPdUDUzrmRyn1',
  vpc: 'U2bFiyyhlMbOaIIM12zLPdUDUzrmRyn1',
}

const DOMAINS: Record<EnvironmentType, string> = {
  production: 'login.cleanlab.ai',
  staging: 'cleanlab-staging.us.auth0.com',
  local_staging: 'cleanlab-staging.us.auth0.com',
  development: 'cleanlab-development.us.auth0.com',
  infra_dev: 'cleanlab-development.us.auth0.com',
  test: 'cleanlab-development.us.auth0.com',
  hosted_dev: 'cleanlab-development.us.auth0.com',
  vpc: 'cleanlab-development.us.auth0.com',
}

const AUDIENCES: Record<EnvironmentType, string> = {
  production: 'https://production.api.cleanlab.ai',
  staging: 'https://staging.api.cleanlab.ai',
  local_staging: 'https://staging.api.cleanlab.ai',
  development: 'https://development.api.cleanlab.ai',
  infra_dev: 'https://development.api.cleanlab.ai',
  test: 'https://development.api.cleanlab.ai',
  hosted_dev: 'https://development.api.cleanlab.ai',
  vpc: 'https://development.api.cleanlab.ai',
}

// XXX we should not export CLIENT_ID and AUDIENCE, but they are used by
// headlessBrowserApi. Once we refactor that code to no longer peek at auth
// internals, we should stop exporting these.
export const CLIENT_ID = CLIENT_IDS[ENV_SETTINGS]
const DOMAIN = DOMAINS[ENV_SETTINGS]
export const AUDIENCE = AUDIENCES[ENV_SETTINGS]

// XXX we should stop using and delete this global Auth0Client, and instead
// only use the Auth0Provider with the useAuth0 hook. But right now, there is a
// lot of code calling auth0.getTokenSilently(), and it's infeasible to
// refactor all of it, so we're leaving this code as-is for now. For the code
// that's using React Query, it can use useAuth0 instead (because everything is
// a hook, and hooks can call hooks), but there's a lot of old code that hasn't
// been migrated over. One slightly easier way to refactor it would be to have
// the caller pass in the token; the direct caller is often a React component,
// and when it isn't, a calling component is a couple stack frames up. But even
// this is a large refactor. For now, this global object lives.
//
// To prevent growing uses of this global auth0 object for other purposes, we
// wrap it, so that only the getTokenSilently method is available.
//
// We also don't want to instantiate the Auth0 client at all in the VPC
// environment, so we just set this to a dummy value in that case (and the auth
// object will never be used).
const auth0Client = AUTH_MODE_OAUTH
  ? {
      getTokenSilently: () => {
        throw new Error('attempt to use Auth0 client in OAuth mode')
      },
    }
  : new Auth0Client({
      domain: DOMAIN,
      clientId: CLIENT_ID,
      authorizationParams: {
        audience: AUDIENCE,
        redirect_uri: window.location.origin,
      },
      cacheLocation: 'localstorage',
      useRefreshTokens: true,
    })

interface TAuth0 {
  getTokenSilently: () => Promise<string>
}

// eslint-disable-next-line no-restricted-syntax
export const auth0: TAuth0 = { getTokenSilently: auth0Client.getTokenSilently.bind(auth0Client) }

export const Auth0ProviderWithHistory: React.FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate()

  const onRedirectCallback = (appState: AppState | undefined) => {
    navigate(appState?.returnTo || window.location.pathname)
  }

  return (
    <Auth0Provider
      domain={DOMAIN}
      clientId={CLIENT_ID}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
      cookieDomain={STUDIO_COOKIE_DOMAIN}
      cacheLocation="localstorage"
      authorizationParams={{
        audience: AUDIENCE,
        redirect_uri: window.location.origin,
      }}
    >
      {children}
    </Auth0Provider>
  )
}
