export interface GetUserQuotaUsageRes {
  user_id: string
  name?: string
  email?: string
  data_storage_limit: number
  data_storage: number
  cleanset_limit: number
  tlm_token_limit: number
  tlm_token_per_minute_limit: number
  created_at: number
  last_updated: number
  num_free_projects_used: number
  num_free_models_used: number
  num_free_cleanset_export_rows_used: number
  num_free_inference_rows_used: number
  num_tlm_tokens_used: number
}

export interface UserQuota {
  userId: string
  name?: string
  email?: string
  dataStorage: number
  dataStorageLimit: number
  cleansetLimit: number
  tlmTokenLimit: number
  createdAt: number
  lastUpdated: number
  numFreeProjectsUsed: number
  numFreeModelsUsed: number
  numFreeCleansetExportRowsUsed: number
  numFreeInferenceExportRowsUsed: number
  numTlmTokensUsed?: number
}

export const queryKeys = {
  quotas: {
    all: () => ['quotas', 'tlm'] as const,
    self: () => [...queryKeys.quotas.all(), 'self'],
  },
}
