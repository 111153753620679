import { Tabs } from '@chakra-ui/react'
import Layout from '@common/layout/Layout'
import Loading from '@common/layout/loading/Loading'
import TopNavTabs from '@common/layout/topNavTabs/TopNavTabs'
import { Button } from '@components/button/Button'
import { useAuth } from '@hooks/useAuth'
import { useMyself } from '@services/user/queries'
import { IS_VPC_ENVIRONMENT } from '@utils/environmentVariables'
import { checkFeatureFlag } from '@utils/functions/checkFeatureFlag'
import { cn } from '@utils/tailwindUtils'
import { ComponentPropsWithoutRef, forwardRef, ReactNode, useEffect, useMemo } from 'react'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import Dashboard from '../dashboard/Dashboard'
import Landing from '../landing/Landing'

const HeaderTabsWrap = forwardRef<HTMLDivElement, ComponentPropsWithoutRef<'div'>>(
  ({ className, ...props }, ref) => {
    return <div ref={ref} className={cn('flex items-center gap-5', className)} {...props}></div>
  }
)

export const HomeLayout = ({ children }: { children: ReactNode }) => {
  return <Layout nav={<HomeTabs />}>{children}</Layout>
}

const useGetHomeTabIndex = () => {
  const location = useLocation()
  return location.pathname.startsWith('/tlm') ? 1 : 0
}

const HOME_TAB_NAMES = ['Dashboard', ...(IS_VPC_ENVIRONMENT ? [] : ['TLM' as const])]

const HomeTabs = () => {
  const { emailConfirmed } = useMyself()
  const tabIndex = useGetHomeTabIndex()
  const navigate = useNavigate()
  const tabNames = useMemo(() => (emailConfirmed ? HOME_TAB_NAMES : []), [emailConfirmed])

  return (
    <HeaderTabsWrap>
      <Tabs
        index={tabIndex}
        onChange={(index) => {
          switch (index) {
            case 0:
              navigate('/')
              return
            case 1:
              navigate('/tlm')
              return
          }
        }}
      >
        <TopNavTabs tabNames={tabNames} />
      </Tabs>
      {checkFeatureFlag('IS_ADMIN') && (
        <Button variant="highContrast" size="small" aria-label="View admin panel" asChild>
          <Link to="/admin">Admin panel</Link>
        </Button>
      )}
    </HeaderTabsWrap>
  )
}

const Home = () => {
  const { isAuthenticated, isLoading, error } = useAuth()
  const { emailConfirmed } = useMyself()
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const signupFromTLM = searchParams.get('signup_origin') === 'TLM'

  console.log('isAuthenticated: ', isAuthenticated)
  console.log('error: ', error)
  console.log('signupFromTLM: ', signupFromTLM)

  // useEffect(() => {
  //   if (signupFromTLM && emailConfirmed) {
  //     searchParams.delete('signup_origin')
  //     setSearchParams(searchParams)
  //     navigate('/tlm')
  //   }
  // }, [emailConfirmed, navigate, searchParams, setSearchParams, signupFromTLM])

  useEffect(() => {
    if (emailConfirmed) {
      if (signupFromTLM) {
        searchParams.delete('signup_origin')
        setSearchParams(searchParams)
        navigate('/tlm')
        console.log('navigating to tlm page...')
      } else {
        navigate('/')
        console.log('navigating to home page...')
      }
    }
  }, [emailConfirmed, navigate, searchParams, setSearchParams, signupFromTLM])

  if (isLoading) {
    return <Loading />
  }
  if (!isAuthenticated || error) {
    return <Landing />
  }
  return (
    <HomeLayout>
      <Dashboard />
    </HomeLayout>
  )
}

export default Home
