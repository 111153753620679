import { TopNavProps } from '@common/layout/topNav/TopNav.types'
import TrialInfo from '@common/topNav/trialInfo/TrialInfo'
import { IconFrameButton } from '@components/iconFrameButton/IconFrameButton'
import { IconBook, IconFile, IconLifeBuoy } from '@components/icons'
import { Tooltip } from '@components/tooltip/Tooltip'
import { useAuth } from '@hooks/useAuth'
import { useCleanlabLocation } from '@hooks/useCleanlabLocation'
import { useEventTracking } from '@hooks/useEventTracking'
import { useSubscription } from '@providers/billing/SubscriptionProvider'
import { MixpanelEvents } from '@services/analytics/MixpanelEvents'
import { useMyself } from '@services/user/queries'
import { useDataLoaderGlobals } from '@utils/dataLoaders/useDataLoaderGlobals'
import { IS_VPC_ENVIRONMENT } from '@utils/environmentVariables'
import { cn } from '@utils/tailwindUtils'
import { useMemo } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'

import { ColorModeSwitcher } from '../colorModeSwitcher/ColorModeSwitcher'
import LoginButton from '../loginButton/LoginButton'
import { LogoImage } from '../logo/Logo'
import NavTitle from '../navTitle/NavTitle'
import UserMenu from '../userMenu/UserMenu'
import { getIntercomTourId, getShowTourButton } from './TopNav.helpers'

const TopNav = (props: TopNavProps) => {
  const { pageTitle } = useDataLoaderGlobals()
  let { nav } = props
  if (pageTitle) {
    nav = <NavTitle title={pageTitle} />
  }
  const { colorModeSwitcherDisabled } = props
  const { isAuthenticated, isLoading } = useAuth()
  const { emailConfirmed } = useMyself()
  const path = useCleanlabLocation()
  const { show: showIntercomMessenger, startTour } = useIntercom()
  const { trackEvent } = useEventTracking()
  const { showFreeTrialBanner } = useSubscription()
  const showTourButton = useMemo(
    () => getShowTourButton(path, window.location.pathname.includes('/labeling')),
    [path]
  )

  const intercomTourId = useMemo(() => getIntercomTourId(path), [path])

  if (isLoading) {
    return null
  }

  return (
    <nav className="w-full print:hidden">
      <div className="border-b border-border-1 bg-white px-8 py-5 dark:bg-neutral-100">
        <div className="mx-auto flex w-full items-center justify-between">
          {/* padding with equal negative margin prevents focus rings from getting clipped */}
          <div className="-m-4 flex h-full min-w-[200px] grow items-center gap-6 overflow-hidden p-4">
            <Tooltip content={emailConfirmed ? 'Home' : ''} placement="bottom">
              <RouterLink
                to="/"
                className={cn(
                  '-m-[2px] rounded-full p-[2px] focus:outline-none focus-visible:outline-none',
                  emailConfirmed ? 'cursor-pointer' : 'cursor-default'
                )}
              >
                <LogoImage />
              </RouterLink>
            </Tooltip>
            {/* padding with equal negative margin prevents focus rings from getting clipped */}
            <div className="-m-4 flex h-full items-center justify-start overflow-hidden p-4">
              {nav}
            </div>
          </div>
          <div className="flex items-center gap-4">
            {showFreeTrialBanner && (
              <div className="pr-4">
                <TrialInfo />
              </div>
            )}

            {!isLoading && isAuthenticated && (
              <>
                {showTourButton && (
                  <IconFrameButton
                    variant="level1"
                    aria-label={`${path} guide`}
                    tooltipContent="Guide"
                    icon={<IconBook className="text-neutral-800" />}
                    size="small"
                    onClick={() => {
                      trackEvent(MixpanelEvents.clickGuide)
                      if (startTour) {
                        startTour(intercomTourId)
                      }
                    }}
                  />
                )}
                <IconFrameButton
                  variant="level1"
                  aria-label="Documentation"
                  tooltipContent="Documentation"
                  icon={<IconFile className="text-neutral-800" />}
                  onClick={() => window.open('https://help.cleanlab.ai', '_blank')}
                  size="small"
                />
                {!IS_VPC_ENVIRONMENT && (
                  <IconFrameButton
                    variant="level1"
                    aria-label="Support"
                    tooltipContent="Support"
                    icon={<IconLifeBuoy className="text-neutral-800" />}
                    size="small"
                    onClick={() => {
                      trackEvent(MixpanelEvents.clickSupport)
                      showIntercomMessenger()
                    }}
                  />
                )}
              </>
            )}
            <ColorModeSwitcher disabled={colorModeSwitcherDisabled} />
            <div className="flex pl-4">{isAuthenticated ? <UserMenu /> : <LoginButton />}</div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default TopNav
