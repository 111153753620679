import React from 'react'

import { SvgIconProps } from '../icons/IconProps.types'

const CleanlabText = ({ width = '102', height = '20', color = '#090E15' }: SvgIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 102 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M91.06 18.83H89.07V1.13H91.06V7.95C92.10 6.54 93.64 5.69 95.39 5.69C98.73 5.69 101.1 8.35 101.1 12.44C101.1 16.60 98.73 19.15 95.39 19.15C93.58 19.15 92.02 18.22 91.06 16.92V18.83ZM91.06 9.54V15.36C91.75 16.47 93.32 17.37 94.88 17.37C97.48 17.37 99.02 15.28 99.02 12.44C99.02 9.60 97.48 7.47 94.88 7.47C93.32 7.47 91.75 8.43 91.06 9.54Z"
        fill={color}
      />
      <path
        d="M84.98 18.83H82.99V17.37C81.93 18.54 80.47 19.15 78.75 19.15C76.57 19.15 74.26 17.69 74.26 14.91C74.26 12.04 76.57 10.69 78.75 10.69C80.50 10.69 81.96 11.24 82.99 12.44V10.13C82.99 8.40 81.61 7.42 79.76 7.42C78.22 7.42 76.97 7.98 75.83 9.20L74.90 7.82C76.28 6.38 77.92 5.69 80.02 5.69C82.73 5.69 84.98 6.92 84.98 10.02V18.83ZM82.99 16.13V13.71C82.22 12.65 80.87 12.12 79.46 12.12C77.61 12.12 76.30 13.29 76.30 14.93C76.30 16.55 77.61 17.72 79.46 17.72C80.87 17.72 82.22 17.19 82.99 16.13Z"
        fill={color}
      />
      <path d="M70.91 18.83H68.92V1.13H70.91V18.83Z" fill={color} />
      <path
        d="M64.85 18.83H62.86V10.45C62.86 8.19 61.72 7.47 59.99 7.47C58.43 7.47 56.94 8.43 56.20 9.46V18.83H54.21V6.01H56.20V7.87C57.10 6.81 58.85 5.69 60.76 5.69C63.44 5.69 64.85 7.05 64.85 9.84V18.83Z"
        fill={color}
      />
      <path
        d="M50.12 18.83H48.13V17.37C47.07 18.54 45.61 19.15 43.88 19.15C41.71 19.15 39.40 17.69 39.40 14.91C39.40 12.04 41.71 10.69 43.88 10.69C45.64 10.69 47.10 11.24 48.13 12.44V10.13C48.13 8.40 46.75 7.42 44.89 7.42C43.35 7.42 42.10 7.98 40.96 9.20L40.03 7.82C41.41 6.38 43.06 5.69 45.16 5.69C47.87 5.69 50.12 6.92 50.12 10.02V18.83ZM48.13 16.13V13.71C47.36 12.65 46.01 12.12 44.60 12.12C42.74 12.12 41.44 13.29 41.44 14.93C41.44 16.55 42.74 17.72 44.60 17.72C46.01 17.72 47.36 17.19 48.13 16.13Z"
        fill={color}
      />
      <path
        d="M24.32 12.41C24.32 8.69 26.97 5.69 30.64 5.69C34.51 5.69 36.80 8.72 36.80 12.57V13.07H26.42C26.58 15.49 28.27 17.51 31.04 17.51C32.50 17.51 33.98 16.92 34.99 15.89L35.95 17.19C34.67 18.46 32.95 19.15 30.85 19.15C27.05 19.15 24.32 16.42 24.32 12.41ZM30.61 7.34C27.88 7.34 26.52 9.65 26.42 11.61H34.83C34.80 9.70 33.53 7.34 30.61 7.34Z"
        fill={color}
      />
      <path d="M21.00 18.83H19.01V1.13H21.00V18.83Z" fill={color} />
      <path
        d="M9.36 19.15C4.34 19.15 0.34 15.44 0.34 9.99C0.34 4.55 4.34 0.84 9.36 0.84C12.57 0.84 14.75 2.35 16.16 4.34L14.30 5.38C13.29 3.89 11.46 2.80 9.36 2.80C5.57 2.80 2.62 5.77 2.62 9.99C2.62 14.19 5.57 17.19 9.36 17.19C11.46 17.19 13.29 16.13 14.30 14.61L16.18 15.65C14.70 17.67 12.57 19.15 9.36 19.15Z"
        fill={color}
      />
    </svg>
  )
}

export default CleanlabText
